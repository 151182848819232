@use '@angular/material' as mat;

/* File for global styling variables */

// Font

$font-family: 'Roboto, "Helvetica Neue", sans-serif';
$font-weight-bold: 500;
$font-weight-extra-bold: 700;
$xs-font-size: 12px;
$s-font-size: 14px;
$m-font-size: 16px;
$l-font-size: 20px;

// Positioning

$bar-height: 64px;
$mobile-bar-height: 56px;
$navigation-rail-width: 72px;

// Spaces

$app-padding: 4vw;

// Card

$card-width: 832px;

// Navigation drawer

$drawer-width: 256px;
$drawer-modal-width: 288px;

// Breakpoints

$screen-xs-max: 599px;
$screen-sm-min: 600px;
$screen-sm-max: 999px;
$screen-md-min: 1000px;

@mixin xs {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin gt-xs {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin lt-md {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin gt-md {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

// Viewport

body {
  --full-height: 100vh;
}

@supports (height: 100dvh) {
  body {
    --full-height: 100dvh;
  }
}
