@use '@angular/material' as mat;

$dark-primary: mat.define-palette(mat.$deep-purple-palette, A100);
$dark-accent: mat.define-palette(mat.$green-palette, A100);
$dark-warn: mat.define-palette(mat.$red-palette, A100);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

.theme-dark {
  --warn: #ef9a9a;
  --warn-inverted: #c62828;
  --warn-soft: #ffcc80;
  --warn-soft-inverted: #fb8c00;
  --success: #81c784;
  --success-inverted: #43a047;
  --background: #212121;
  --surface: #333;
  --dialog: #424242;
  --alt-surface: #323232;
  --on-background: #fcfcfc;
  --on-surface: #fcfcfc;
  --table-a: #424242;
  --table-b: #505050;
  --border: #595959;
  --unlocked: #004d40;
  --green: #81c784;
  --red: #e57373;
  --yellow: #fff176;
  --blue: #7986cb;
  --grey: #aaa;
  --grey-light: #444;
  --black: #000;
  --bar-blue: #027db9;
  --bar-yellow: #ffca28;
  --bar-teal: #4d8076;
  --bar-deep-orange: #e64a19;
  --bar-purple: #8e79ab;
  --bar-brown: #9f6b3f;
  --bar-green: #787b1d;
  --bar-pink: #eb0054;
  --bar-cyan: #00838f;
  --bar-orange: #fb8c00;
  --bar-light-green: #558b2f;
  --bar-red: #ef5350;
  --likert-strongly-agree: #3f51b5;
  --likert-agree: #7986cb;
  --likert-neither: #e0e0e0;
  --likert-disagree: #ff8a65;
  --likert-strongly-disagree: #ff5722;
}
