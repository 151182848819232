@use '@angular/material' as mat;
@use 'sass:map';
@use 'angular-material-css-vars/public-util' as mat-css-utilities;

@mixin theme($theme, $isLight: true) {
  $primary-palette: map.get($theme, primary);
  $secondary-palette: map.get($theme, accent);
  $primary: mat.get-color-from-palette(
    $primary-palette,
    if($isLight == true, 500, A100)
  );
  $primary-variant: mat.get-color-from-palette(
    $primary-palette,
    if($isLight == true, 50, 200)
  );
  $on-primary: mat.get-color-from-palette($primary-palette, default-contrast);
  $secondary: mat.get-color-from-palette(
    $secondary-palette,
    if($isLight == true, 500, A100)
  );
  $on-secondary: mat.get-color-from-palette(
    $secondary-palette,
    default-contrast
  );

  // Primary

  %primary {
    color: $primary;
  }

  %primary-variant {
    background-color: $primary-variant;
  }

  %primary-background {
    background-color: $primary;
    color: $on-primary;
  }

  %primary-border {
    border: 1px solid $primary;
  }

  %primary-border-color {
    border-color: $primary;
  }

  %primary-shadow {
    box-shadow: 0 0 3px $primary;
  }

  // Secondary

  %secondary {
    color: $secondary;
  }

  %secondary-variant {
    background-color: mat.get-color-from-palette($secondary-palette, 100);
  }

  %secondary-background {
    background-color: $secondary;
    color: $on-secondary;
  }

  %on-secondary {
    color: $on-secondary;
  }
}
