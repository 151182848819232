@use '@angular/material' as mat;
@use 'sass:color';
@import '../../../../variables';

$light-primary: mat.define-palette(mat.$deep-purple-palette, 600);
$light-accent: mat.define-palette(mat.$green-palette, 800);
$light-warn: mat.define-palette(mat.$red-palette, 800);
$custom-typography: mat.define-typography-config(
  $font-family: $font-family,
  $button:
    mat.define-typography-level(14px, 120%, $font-weight-bold, $font-family, 0),
  $body-1: mat.define-typography-level(16px, 120%, normal, $font-family, 0),
  $body-2: mat.define-typography-level(16px, 120%, normal, $font-family, 0),
);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
    typography: $custom-typography,
  )
);

.theme-light {
  --warn: #c62828;
  --warn-inverted: #ef9a9a;
  --warn-soft: #fb8c00;
  --warn-soft-inverted: #ffcc80;
  --success: #43a047;
  --success-inverted: #81c784;
  --background: #f5f5f5;
  --surface: #fdfdfd;
  --dialog: #fcfcfc;
  --alt-surface: #eee;
  --on-background: #212121;
  --on-surface: #212121;
  --table-a: #e0e0e0;
  --table-b: #eee;
  --border: #e0e0e0;
  --unlocked: #ecf7ed;
  --green: #43a047;
  --red: #e53935;
  --yellow: #fdd835;
  --blue: #3949ab;
  --grey: #9e9e9e;
  --grey-light: #e0e0e0;
  --black: #212121;
  --bar-blue: #027db9;
  --bar-yellow: #ffca28;
  --bar-teal: #4d8076;
  --bar-deep-orange: #e64a19;
  --bar-purple: #8e79ab;
  --bar-brown: #9f6b3f;
  --bar-green: #787b1d;
  --bar-pink: #eb0054;
  --bar-cyan: #00838f;
  --bar-orange: #fb8c00;
  --bar-light-green: #558b2f;
  --bar-red: #ef5350;
  --likert-strongly-agree: #3f51b5;
  --likert-agree: #7986cb;
  --likert-neither: #e0e0e0;
  --likert-disagree: #ff8a65;
  --likert-strongly-disagree: #ff5722;
}
